import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'instaUsername',
    'instaUsername1',
    'instaUsernameCopy',
    'fullName',
    'email',
    'spinner',
    'lock',
    'progressBar',
    'progressBarText',
    'progressSuccess',
    'profileName',
    'followers',
    'followings',
    'profilePic',
    'post',
    'step1',
    'step2',
    'step3',
    'scrapError'
  ]

  connect() {

    this.stripe = Stripe('pk_test_BREik0gb7T7UhyJHozDDxFGS')
    const elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Mukta:400,600,700&display=swap',
        }
      ],
    })

    const elementsStyles = {
      base: {
        fontFamily: 'Mukta, sans-serif',
        fontSize: '16px',
        fontWeight: '600',
        fontSmoothing: 'antialiased',
        color: '#696871',
        '::placeholder': {
          color: '#9c9aaa'
        }
      },
      invalid: {
        color: "#ff5252",
        iconColor: "#ff5252",
      },
    }

    /* MORE STRIPE ELEMENTS ACTIONS */
    this.cardNumber = elements.create("cardNumber", {style: elementsStyles})
    this.cardNumber.mount("#subscription_step3_form_cardNumber")

    this.expiration = elements.create("cardExpiry", {style: elementsStyles})
    this.expiration.mount("#subscription_step3_form_expiration")

    this.cvcNumber = elements.create("cardCvc", {style: elementsStyles})
    this.cvcNumber.mount("#subscription_step3_form_cvcNumber")

    /* Set up event listeners on card */
    this.cardNumber.addEventListener('change', ({error}) => {
      this.displayError(error)
    })
    this.expiration.addEventListener('change', ({error}) => {
      this.displayError(error)
    })
    this.cvcNumber.addEventListener('change', ({error}) => {
      this.displayError(error)
    })
  }

  displayError(error) {
    if (error) {
      document.getElementById('card-errors').classList.remove('hide');
      document.getElementById('card-errors').classList.add('d-block');
      document.getElementById('card-errors-msg').textContent = error.message;
    } else {
      document.getElementById('card-errors').classList.add('hide');
      document.getElementById('card-errors').classList.remove('d-block');
      document.getElementById('card-errors-msg').textContent = '';
    }
  }

  clear() {
    this.step1Target.classList.remove('hidden')
    this.step2Target.classList.add('hidden')
    this.step3Target.classList.add('hidden')
    this.progressSuccessTarget.classList.add('hidden')
    this.progressBarTextTarget.classList.remove('hidden')
    this.progressBarTarget.style.width = '0%'
    this.scrapErrorTarget.classList.add('hidden')
  }

  firstStep(event) {
    event.preventDefault()
    if(this.instaUsername1Target.value) {
      this.instaUsernameCopyTarget.innerText = this.instaUsername1Target.value
      this.username = this.instaUsername1Target.value
      this.instaUsername1Target.value = ''
    } else {
      this.instaUsernameCopyTarget.innerText = this.instaUsernameTarget.value
      this.username = this.instaUsernameTarget.value
      this.instaUsernameTarget.value = ''
    }
    this.loading = true
    this.revealStep2()
    this.loadingBar(0)

  }

  loadingBar(width) {
    
    if(width < 100) {
      width += 5
      this.progressBarTarget.style.width = width + '%'
      setTimeout(() => {
        this.loadingBar(width)
      }, 500)
    } else if(width == 100) {
      this.progressBarTextTarget.classList.add('hidden')
      if(!this.isScrappingError)
        this.progressSuccessTarget.classList.remove('hidden')
    }
  }

  async revealStep2() {
    try {
      const response = await axios.post('/get-profile?username=' + this.username)
      const profile = response.data
      console.log(profile)
      this.finishLoading = true
      if(!profile.is_private) {
        this.step1Target.classList.add('hidden')
        this.step2Target.classList.remove('hidden')
        this.postTargets.forEach((post, i) => {
          post.src = `data:image/png;base64, ${profile.posts[i].base64}`
          post.dataset.postUrl = profile.posts[i].shortcode
        })
      } else {
        this.scrappingError()
      }
  
    }
    catch(error) {
      console.log(error)
      this.isScrappingError = true
      this.scrappingError()
    }

  }
  scrappingError() {
    this.scrapErrorTarget.classList.remove('hidden')
    this.step2Target.classList.add('hidden')
    this.progressSuccessTarget.classList.add('hidden')
    this.progressBarTextTarget.classList.add('hidden')
  }

  loadingActive() {
    this.lockTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
  }

  selectPost(e) {
    this.postTargets.forEach((post, i) => {
      post.classList.remove('selected')
    })
    e.target.classList.add('selected')
    this.selectedPost = e.target.dataset.postUrl
  }
  
  step3() {
    this.step2Target.classList.add('hidden')
    this.step3Target.classList.remove('hidden')
  }

  loadingDisactive() {
    this.lockTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('hidden')
  }

  

  pay(event) {
    event.preventDefault()
    this.loadingActive()
    
    // Setup authenticity token
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    /* Create your 'card' payment method */
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      /* Reference: https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details */
      billing_details: {
        name: this.fullNameTarget.value,
        email: this.emailTarget.value
      },
    }).then((result) => {
      if (result.error) {
        this.displayError(result.error);
        this.loadingDisactive()
        console.error('Error creating Stripe Payment Method: ', result.error)
      } else {
        /* Proceed to next step of creating customer and subscription */
        this.stripePaymentMethodHandler(result.paymentMethod)
      }
    })
  }

  stripePaymentMethodHandler(paymentMethod) {
    axios.post('/pay', {
      user: {
        email: this.emailTarget.value,
        full_name: this.fullNameTarget.value,
        username: this.username
      },
      stripe_plan: {
        payment_method_id: paymentMethod.id
      }
    }).then(response => {
      if(response.data.error) {
        this.displayError(error.message)
        this.loadingDisactive()
      } else {
        this.customerId = response.data.customer_id
        console.log(response.data)
        this.stripe.confirmCardPayment(response.data.intent.client_secret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.fullNameTarget.value,
              email: this.emailTarget.value
            },
          },
        }).then((result) => {
          if(result.error) {
            this.displayError(result.error.message)
            console.warn(result.error)
            this.loadingDisactive()
          } else {
            axios.post(`/sign-in-user?customer_id=${this.customerId}`, {
              order: {
                profile: this.profile,
              }
            }).then(response => {
              window.location.replace(response.data.redirect_url)
            })
          }
        })
      }
    })
  }
}