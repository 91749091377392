import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ['username', 'posts', 'spinner', 'post', 'shortcode']

  connect() {
    this.setupAuthenticityToken()
    this.loadPosts()
  }

  setupAuthenticityToken() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }

  async loadPosts() {
    try {
      this.loadingActive()
      const response = await axios.post('/get-posts')
      console.log(response.data)
      this.postsTarget.innerHTML = response.data.posts.map(post => {
        return `
          <div class="post">
            <img data-likes-target="post" data-shortcode="${post.shortcode}" data-action="click->likes#selectPost" src="data:image/png;base64, ${post.base64}" />
          </div>
        `
      }).join('')
      this.loadingDisactive()
    }
    catch(error) {
      console.log(error)
      this.loadingDisactive()
    }
  }

  loadingActive() {
    this.spinnerTarget.style.display = 'flex'
  }

  loadingDisactive() {
    this.spinnerTarget.classList.remove('d-flex')
    this.spinnerTarget.style.display = 'none'
  }

  selectPost(e) {
    this.postTargets.forEach((post, i) => {
      post.classList.remove('selected')
    })
    e.target.classList.add('selected')
    document.getElementById('shortcode').value = e.currentTarget.dataset.shortcode
  }
}