import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ['email', 'unsubOk', 'unsubError']

  connect() {
    this.setupAuthenticityToken()
  }

  setupAuthenticityToken() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }


  unsubscribeUser(e) {
    e.preventDefault()

    axios.post('/stop-plan', {
      user: {
        email: this.emailTarget.value
      }
    }).then(response => {
      console.log(response.data)
      if(response.success) {
        this.unsubError()
      } else {
        this.unsubSuccess()
      }
    })
  }

  unsubError() {    
    this.unsubOkTarget.classList.add('hide')
    this.unsubErrorTarget.classList.remove('hide')
  }

  unsubSuccess() {
    this.unsubOkTarget.classList.remove('hide')
    this.unsubErrorTarget.classList.add('hide')
  }
}